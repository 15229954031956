import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
let API =  process.env.REACT_APP_API + "/v2/api/org";

let userId;
let organizationId;
let token;
let headers;
const setTokenValues = () => {
  const data = Cookies.get("loginData")
    ? JSON.parse(Cookies.get("loginData"))
    : null;
  userId = data?.id;
  organizationId = data?.organizationId;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// `${API}/admin/auth`;

/**GET ALL USERS ORGANIZATION */
export const getAllOrgUsers = createAsyncThunk(
  "management/getAllOrgUsers",
  async (obj, thunkAPI) => {
    try {
      console.log(obj)
      setTokenValues();
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/cowork/auth/coworkuser/${organizationId}/getAllOrgUsers/${obj.pageNumber}/${obj.perPageCount}/?searchQuery=${obj.searchValue}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**CREATE ORGANIZATION USER  */
export const createOrgUser = createAsyncThunk(
  "management/createOrgUser",
  async (obj, thunkAPI) => {
    try {
      console.log(`${process.env.REACT_APP_API}/api/cowork/auth/admin/${userId}/organizationId/${organizationId}/createuser`)
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/cowork/auth/admin/${userId}/organizationId/${organizationId}/createuser`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** UPDATE USER ROLES  */
export const editOrgUserRole = createAsyncThunk(
  "management/editOrgUserRole",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        `${API}/admin/${userId}/organizationId/${organizationId}/editUserRole`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** UPDATE USER ROLES  */
export const updateUserPrograms = createAsyncThunk(
  "management/updateUserPrograms",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        `${API}/admin/${userId}/organizationId/${organizationId}/updateUserPrograms`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**DELETE ORGANIZATION USER  */
export const deleteOrgUser = createAsyncThunk(
  "management/deleteOrgUser",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        `${API}/user/${obj.userId}/deleteUser`,
        {
          method: "DELETE",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return obj.userId;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**GET ALL ORGANIZATION PERMISSIONS  */
export const getAllOrgPermissions = createAsyncThunk(
  "management/getAllOrgPermissions",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/${organizationId}/getModuleWisePermission`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "ERROR GET ALL ORGANIZATION PERMISSIONS");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**GET ALL ORGANIZATION PERMISSIONS  */
export const createNewOrgRole = createAsyncThunk(
  "management/createNewOrgRole",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/user/${organizationId}/createRole`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "ERROR GET ALL ORGANIZATION PERMISSIONS");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**GET ALL ORGANIZATION ROLES  */
export const getAllOrgRoles = createAsyncThunk(
  "management/getAllOrgRoles",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/${organizationId}/getAllOrgRoles`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "ERROR GET ALL ORGANIZATION PERMISSIONS");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// GET PERMISSIONS OF A PERTICULAR ROLE
export const getRoleWisePermissions = createAsyncThunk(
  "management/getRolePermissions",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/${organizationId}/role/${obj.roleName}/getRoleWisePermissions`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "ERROR GET ALL ORGANIZATION PERMISSIONS");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// GET PERMISSIONS OF A PERTICULAR ROLE
export const editRolePermissions = createAsyncThunk(
  "management/editRolePermissions",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/${organizationId}/role/${obj.roleName}/editRolePermissions`,
        {
          method: "PATCH",
          headers,
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "ERROR GET ALL ORGANIZATION PERMISSIONS");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**GET ALL ORGANIZATION PROGRAMS WITH COHORT  */
export const getAllOrgProgramsWithCohorts = createAsyncThunk(
  "management/getAllOrgProgramsWithCohorts",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/${organizationId}/getAllProgramsWithCohort`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "ERROR GET ALL ORGANIZATION PERMISSIONS");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialStateValues = {
  isManagementSliceFetching: false,
  isManagementSliceFetchingSmall: false,
  isManagementSliceSuccess: false,
  isManagementSliceError: false,
  managementSliceErrorMessage: "",
  managementSliceSuccessMessage: "",
  allOrgUsers: [],
  allOrgPermissions: [],
  allOrgRoles: [],
  roleWisePermissions: [],
  allOrgProgramsWithCohorts: [],
  userCount:0,
};

const userManagingSlice = createSlice({
  name: "management",
  initialState: initialStateValues,
  reducers: {
    // Reducer Action to clear initial states after success from any AsynCThunk call
    clearManagementSliceStates: (state, action) => {
      state.isManagementSliceFetching = false;
        state.isManagementSliceFetchingSmall = false;
        state.isManagementSliceSuccess = false;
        state.isManagementSliceError = false;
        state.managementSliceErrorMessage = "";
    },
    clearManagementSlicePermissionData: (state, action) => {
      state.roleWisePermissions = [];
    },
    clearManagementSliceData: (state, action) => {
      state.userCount=0;
      state.allOrgUsers=[];
    },
  },

  extraReducers: (builder) => {
    // Cases for GET ALL USERS ORGANIZATION
    builder.addCase(getAllOrgUsers.fulfilled, (state, { payload }) => {
      // console.log(payload, "------------------pay-create-user--------");
      
      state.isManagementSliceFetching = false;
      console.log(payload);
      state.allOrgUsers = [...state.allOrgUsers, ...payload.Data];
      state.userCount=payload?.count;
      return state;
    });
    builder.addCase(getAllOrgUsers.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.isManagementSliceError = true;
      state.managementSliceErrorMessage =
        payload?.message || "Something Went Wrong";
    });
    builder.addCase(getAllOrgUsers.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for CREATE ORGANIZATION USER
    builder.addCase(createOrgUser.fulfilled, (state, { payload }) => {
      console.log(payload, "------------------pay-create-user--------");
      state.isManagementSliceFetching = false;
      state.allOrgUsers = [payload.Data, ...state.allOrgUsers];
      state.managementSliceSuccessMessage = payload?.Message || "user created";
      state.isManagementSliceSuccess = true;

      return state;
    });
    builder.addCase(createOrgUser.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.isManagementSliceError = true;
      state.managementSliceErrorMessage =
        payload?.Message || "Something Went Wrong";
    });
    builder.addCase(createOrgUser.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for EDIT ORGANIZATION USER ROLES
    builder.addCase(editOrgUserRole.fulfilled, (state, { payload }) => {
      const updatedUserIndex = state.allOrgUsers.findIndex(
        (user) => user.id === payload.Data.id
      );
      state.isManagementSliceFetching = false;
      if (updatedUserIndex !== -1) {
        state.allOrgUsers[updatedUserIndex] = payload.Data;
      } else {
        state.allOrgUsers.unshift(payload.Data);
      }
      state.isManagementSliceSuccess = true;
      state.managementSliceSuccessMessage = payload?.Message || "Success !";
      return state;
    });
    builder.addCase(editOrgUserRole.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.isManagementSliceError = true;
      state.managementSliceErrorMessage =
        payload?.message || "Something Went Wrong";
    });
    builder.addCase(editOrgUserRole.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for UPDATE USER PROGRAMS
    builder.addCase(updateUserPrograms.fulfilled, (state, { payload }) => {
      const updatedUserIndex = state.allOrgUsers.findIndex(
        (user) => user.id === payload.Data.id
      );
      state.isManagementSliceFetchingSmall = false;
      if (updatedUserIndex !== -1) {
        state.allOrgUsers[updatedUserIndex] = payload.Data;
      } else {
        state.allOrgUsers.unshift(payload.Data);
      }
      state.isManagementSliceSuccess = true;
      state.managementSliceSuccessMessage = payload?.Message || "Success !";
      return state;
    });
    builder.addCase(updateUserPrograms.rejected, (state, { payload }) => {
      state.isManagementSliceFetchingSmall = false;
      state.isManagementSliceError = true;
      state.managementSliceErrorMessage =
        payload?.message || "Something Went Wrong";
    });
    builder.addCase(updateUserPrograms.pending, (state, { payload }) => {
      state.isManagementSliceFetchingSmall = true;
    });

    // TODO
    // Cases for DELETE ORGANIZATION USER
    builder.addCase(deleteOrgUser.fulfilled, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.allOrgUsers = state.allOrgUsers.filter(
        (orgUser) => orgUser.id !== payload
      );
      state.isManagementSliceSuccess = true;
      state.managementSliceSuccessMessage =
        payload?.Message || "Deleted successfully";
      return state;
    });
    builder.addCase(deleteOrgUser.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.isManagementSliceError = true;
      state.managementSliceErrorMessage =
        payload?.message || "Something Went Wrong";
    });
    builder.addCase(deleteOrgUser.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for GET ALL ORGANIZATION PERMISSIONS
    builder.addCase(getAllOrgPermissions.fulfilled, (state, { payload }) => {
      state.allOrgPermissions = payload.Data;
      return state;
    });
    builder.addCase(getAllOrgPermissions.rejected, (state, { payload }) => {});
    builder.addCase(getAllOrgPermissions.pending, (state, { payload }) => {});

    // Cases for CREATE NEW ORG ROLE
    builder.addCase(createNewOrgRole.fulfilled, (state, { payload }) => {
      console.log(payload, "pay-createNewOrgRole");
      state.isManagementSliceFetching = false;
      state.isManagementSliceSuccess = true;
      state.managementSliceSuccessMessage = payload?.Message || "Success";
      // TODO
      state.allOrgRoles = [...state.allOrgRoles, payload.Data];
      return state;
    });
    builder.addCase(createNewOrgRole.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
    });
    builder.addCase(createNewOrgRole.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for GET ALL ORGANIZATION ROLES
    builder.addCase(getAllOrgRoles.fulfilled, (state, { payload }) => {
      // console.log(payload.Data, "pay-getAllOrgRoles");
      // state.isManagementSliceFetching = false;
      state.allOrgRoles = payload.Data;
      // state.isManagementSliceSuccess = true;

      return state;
    });
    builder.addCase(getAllOrgRoles.rejected, (state, { payload }) => {
      // state.isManagementSliceFetching = false;
      // state.isManagementSliceError = true;
      // state.managementSliceErrorMessage =
      // payload?.message || "Something Went Wrong";
    });
    builder.addCase(getAllOrgRoles.pending, (state, { payload }) => {
      // state.isManagementSliceFetching = true;
    });

    // Cases GET ROLE WISE PERMISSIONS
    builder.addCase(getRoleWisePermissions.fulfilled, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.roleWisePermissions = payload?.Data?.permission;
      return state;
    });
    builder.addCase(getRoleWisePermissions.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.managementSliceErrorMessage =
        payload?.message || "Something Went Wrong";
    });
    builder.addCase(getRoleWisePermissions.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for Edit Permisions of a role
    builder.addCase(editRolePermissions.fulfilled, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.isManagementSliceSuccess = true;
      state.managementSliceSuccessMessage =
        payload?.Message || "Permissions updated !";
      return state;
    });
    builder.addCase(editRolePermissions.rejected, (state, { payload }) => {
      state.isManagementSliceFetching = false;
      state.managementSliceErrorMessage =
        payload?.message || "Something Went Wrong";
    });
    builder.addCase(editRolePermissions.pending, (state, { payload }) => {
      state.isManagementSliceFetching = true;
    });

    // Cases for GET ALL ORGANIZATION PROGRAMS WITH COHORT
    builder.addCase(
      getAllOrgProgramsWithCohorts.fulfilled,
      (state, { payload }) => {
        // console.log(payload.Data, "pay-getAllOrgRoles");
        // state.isManagementSliceFetching = false;
        state.allOrgProgramsWithCohorts = payload.Data;
        // state.isManagementSliceSuccess = true;

        return state;
      }
    );
    builder.addCase(
      getAllOrgProgramsWithCohorts.rejected,
      (state, { payload }) => {
        // state.isManagementSliceFetching = false;
        // state.isManagementSliceError = true;
        // state.managementSliceErrorMessage =
        // payload?.message || "Something Went Wrong";
      }
    );
    builder.addCase(
      getAllOrgProgramsWithCohorts.pending,
      (state, { payload }) => {
        // state.isManagementSliceFetching = true;
      }
    );
  },
});

export const {
  clearManagementSliceStates,
  clearManagementSlicePermissionData,
  clearManagementSliceData
} = userManagingSlice.actions;
export default userManagingSlice;

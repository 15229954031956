import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";
import PageLoader from "../../Components/loaders/PageLoader/PageLoader";

import { resetpassword, logout } from "../../redux/authentication/authSlice";
import "./ResetPassword.css";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Image from "../../Components/Image/Image";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';
import Lottie from "lottie-react";
import SignIn from "../../assets/lottie/SignIn.json";

const ResetPassword = () => {

  const formObject = { newPassword: "", confirmPassword: "" };
  const [formData, setFormData] = useState(formObject);
  const { token } = useParams();
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const userid = Cookies.get("loginData")
    ? JSON.parse(Cookies.get("loginData"))?.id
    : null;
  console.log(token)
  const {
    isAuthSliceFetching,
    isAuthSliceSuccess,
    isAuthSliceError,
    authSliceErrorMessage,
    isResetSliceSuccess,
  } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let newErrors = {
    newPassword: "",
    confirmPassword: "",
  };
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Split the URL by '/'
    const parts = currentUrl.split('/');

    // Get the base URL by joining the parts up to the first slash
    const base = parts.slice(0, 3).join('/');

    // Set the base URL in state
    setBaseUrl(base);
  }, []);

  useEffect(() => {
    if (isResetSliceSuccess) {
      toast.success("Password Reset Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false, // Display progress bar
      });
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 3000);
    }
  }, [isResetSliceSuccess]);

  useEffect(() => {
    //  Clears all the states in the redux store
    // dispatch(logout())
    if (isAuthSliceError) {
      errorToast();
    }
  }, [isAuthSliceError]);

  /**Handling formData with single onChange */
  const onChangeFormData = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({ newPassword: "", confirmPassword: "" });
  };
  function validatePassword(password) {
    console.log(password, formData.confirmPassword);
    // Check if the password is at least 8 characters long
    if (password.length < 8) {
      newErrors.confirmPassword = "Password should be at least 8 character";
      setErrors(newErrors);
      return false;
    }

    // Check if the password contains at least one lowercase letter
    var lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(password)) {
      console.log("first", password);
      newErrors.confirmPassword =
        "Password should contrain a lowercase character";
      setErrors(newErrors);
      return false;
    }

    // Check if the password contains at least one uppercase letter
    var uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(password)) {
      newErrors.confirmPassword =
        "Password should contrain a uppercase character";
      setErrors(newErrors);
      return false;
    }

    // Check if the password contains at least one number
    var numberRegex = /[0-9]/;
    if (!numberRegex.test(password)) {
      newErrors.confirmPassword =
        "Password should contrain at least one number";
      setErrors(newErrors);
      return false;
    }

    // Check if the password contains at least one special character
    var specialCharRegex = /[!@#$%^&*()\-=_+[\]{}|;':"\\|,.<>/?]/;
    if (!specialCharRegex.test(password)) {
      newErrors.confirmPassword =
        "Password should contrain at least one special character";
      setErrors(newErrors);
      return false;
    }

    // Check if the password contains any spaces
    if (password.indexOf(" ") !== -1) {
      newErrors.confirmPassword = "Password should not conatin spaces";
      setErrors(newErrors);
      return false;
    }
    if (password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Password does not match";
      setErrors(newErrors);
      return false;
    }
    setErrors(newErrors);

    return true;
  }
  const handleSignIn = () => {
    let newErrors = { newPassword: "", confirmPassword: "" };

    if (!formData.newPassword) {
      newErrors.newPassword = "New Password is required.";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm New Password is required.";
    }




    if (newErrors.newPassword || newErrors.confirmPassword) {
      setErrors(newErrors);
      return;
    }
    if (validatePassword(formData.newPassword)) {
      dispatch(resetpassword({ token: token, password: formData.newPassword, }));
      formData.newPassword = "";
      formData.confirmPassword = "";
      setFormData(formData);
    }
  };

  const [type, setType] = useState(true);

  const errorToast = () => {
    toast.error(authSliceErrorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000, // Close the toast after 1 seconds
      hideProgressBar: false, // Display progress bar
      pauseOnHover: true, // Pause the timer when hovering over the toast
    });
  };

  return (
    <>
      <ToastContainer />
      {
        <div className="authenticationWrapper">
          {isAuthSliceFetching && <PageLoader />}

          <Lottie animationData={SignIn} loop={true} className="LottieLogin" />

          <div className="LoginWrapperBox">
            <div className="LoginBoxContent">

              <div className="headinglogin">
                <h2 className="signtext">Reset password</h2>
                <p className="text12 mt-8">Create your new password for CoWork</p>
              </div>

              <div className="loginWrap">
                <InputField
                  notImp={"notimp"}
                  labelName={"Create new password"}
                  placeholder={"Enter your password"}
                  onChange={onChangeFormData}
                  name="newPassword"
                  type={type === true ? "password" : "text"}
                  isError={errors.newPassword ? true : false}
                  inputIcon={type === true ? "eyeSlash" : "blueEye"}
                  onclick={() => setType(!type)}
                  erroMessage={errors.newPassword}
                  value={formData.newPassword}
                />
                <InputField
                  notImp={"notimp"}
                  labelName={"Confirm password"}
                  placeholder={"Re-enter your password"}
                  onChange={onChangeFormData}
                  name="confirmPassword"
                  type={type === true ? "password" : "text"}
                  isError={errors.confirmPassword ? true : false}
                  erroMessage={errors.confirmPassword}
                  inputIcon={type === true ? "eyeSlash" : "blueEye"}
                  onclick={() => setType(!type)}
                  value={formData.confirmPassword}
                />

                <Button
                  onClick={handleSignIn}
                  className={"BlueFillButton loginButton"}
                  name={"Reset password"}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ResetPassword;

import './App.css';
import ApplicationRoutes from "./Routes";

function App() {
  return (
    <>
      <ApplicationRoutes />
    </>
  );
}

export default App;

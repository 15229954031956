import React, { lazy } from "react";
import MainLayout from "../Layouts/MainLayout/index.js";
import FallbackLoader from "../Components/loaders/FallbackLoader/FallbackLoader";
import DynamicDashboard from "./DynamicDashboard.js";
import { PrivateRoute } from "./PrivateRoute.js";

import { Outlet } from "react-router-dom";

const MyBookings = lazy(() => 
  import("../Pages/MyBookings/MyBookings.jsx")
);

const AvailableRooms = lazy(()=>
  import("../Pages/AvailableRooms/AvailableRooms.jsx")
);

const GetAllBookings = lazy(() => 
  import("../Pages/GetAllBookings/GetAllBookings.jsx")
);

const AllRooms = lazy(() => 
  import("../Pages/AllRooms/AllRooms.jsx")
);

const UserDashboard = lazy(() =>
  import("../Pages/UserManagementDashboard/UserManagementDashboard.jsx")
);
const MainRoutes = [
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<FallbackLoader />}>
                <DynamicDashboard />
              </React.Suspense>
            ),
          },
          {
            path: "mybookings",
            element: (
              <React.Suspense fallback={<FallbackLoader />}>
                <MyBookings />
              </React.Suspense>
            ),
          },
          {
            path: "/",
            element: (
              <React.Suspense fallback={<FallbackLoader />}>
                <AvailableRooms />
              </React.Suspense>
            ),
          },  
          {
            path: "/usermanagement",
            element: (
              <React.Suspense fallback={<FallbackLoader />}>
                <UserDashboard />
              </React.Suspense>
            ),
          },       
          {
            path: "allroombookings",
            element: (
              <React.Suspense fallback={<FallbackLoader />}>
                <GetAllBookings />
              </React.Suspense>
            ),
          },
          {
            path: "allrooms",
            element: (
              <React.Suspense fallback={<FallbackLoader />}>
                <AllRooms />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
];

export default MainRoutes;

import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import AuthRoutes from "./AuthRoute";
import MainRoutes from "./MainRoute";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function ApplicationRoutes() {
  const routes = useRoutes([...MainRoutes, ...AuthRoutes]);
  const toastId = useRef(null);
  useEffect(() => {

    function handleOffline() {
      toastId.current = toast.error('You are currently offline.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    function handleOnline() {
      toast.dismiss(toastId.current);
    }

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);
  return (
    <div className="App">
      {routes}
    </div>
  );
}





